import ApiClient from "../api/ApiClient";
import { Variant } from "./ProductVariantService";

export interface ProductEquation {
    fullLength: string
    perFoot: string
}

export interface ProductEquationPriceCheck {
    rows: {
        variantTitle: string,
        fullLength: string,
        perFoot: string
    }[]
}

export const ProductEquationService = () => {

    const get = (async (productId: string): Promise<ProductEquation> => {
        return ApiClient.getInstance().get<ProductEquation>(`/api/1/products/${productId}/equation`);
    });

    const update = (async (productId: string, equation: ProductEquation): Promise<ProductEquation> => {
        return ApiClient.getInstance().put<ProductEquation>(`/api/1/products/${productId}/equation`, equation);
    });

    const deletePe = (async (productId: string): Promise<void> => {
        return ApiClient.getInstance().delete <void>(`/api/1/products/${productId}/equation`);
    });

    const priceChecker = (async (productId: string, fullLength?: string, perFoot?: string): Promise<ProductEquationPriceCheck> => {
        return ApiClient.getInstance().post<ProductEquationPriceCheck>(`/api/1/product-equation-price-checker`, {
            productId,
            fullLength,
            perFoot
        });
    });

    return {
        getProductEquation: get,
        updateProductEquation: update,
        deleteProductEquation: deletePe,
        runPriceChecker: priceChecker
    }
}
