import React, { useEffect, useState } from "react";
import AppPage from "../../components/page/AppPage";
import { Breadcrumb, BreadcrumbItem, Spinner, Table } from "react-bootstrap";
import QuoteOrderItemsTable from "../itemstable/QuoteOrderItemsTable";
import { useNavigate, useParams } from "react-router-dom";
import { logApiError } from "../../../../services/api/ApiUtils";
import { QUOTES } from "../../PlatformStack";
import {
    QuoteDetail,
    QuoteService
} from "../../../../services/quote/QuoteService";

interface Props {
}

const SpecificQuote = ({}: Props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { getQuote } = QuoteService();
    const [loading, setLoading] = useState<boolean>();
    const [quote, setQuote] = useState<QuoteDetail>();

    useEffect(() => {
        if (loading || !id) {
            return;
        }

        const loadQuote = async (quoteId: string) => {
            setLoading(true);
            try {
                const resp = await getQuote(quoteId);
                setQuote(resp);
            } catch (e) {
                logApiError("Error loading quote.", e);
            } finally {
                setLoading(false);
            }
        };

        loadQuote(id);
    }, []);

    return (
        <AppPage>
            <>
                <h3 className={"heading"}>Quotes</h3>

                {loading && (
                    <>
                        <Spinner animation={"border"} />
                        <p>Loading...</p>
                    </>
                )}
                {quote && !loading && (
                    <>
                        <Breadcrumb>
                            <BreadcrumbItem onClick={() => navigate(QUOTES.uri)}>
                                Quotes
                            </BreadcrumbItem>
                            <BreadcrumbItem active>{quote.ref}</BreadcrumbItem>
                        </Breadcrumb>
                        <h4 className={"customer"}>{quote.customerName}</h4>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginRight: '3em' }}>
                                <ul className="customer-address">
                                    {quote.shippingAddress && (
                                        <>
                                            <b>Shipping Address</b>
                                            {quote.shippingAddress.lineOne && (
                                                <li>{quote.shippingAddress.lineOne}</li>
                                            )}
                                            {quote.shippingAddress.lineTwo && (
                                                <li>{quote.shippingAddress.lineTwo}</li>
                                            )}
                                            {quote.shippingAddress.lineThree && (
                                                <li>{quote.shippingAddress.lineThree}</li>
                                            )}
                                            {quote.shippingAddress.lineFour && (
                                                <li>{quote.shippingAddress.lineFour}</li>
                                            )}
                                            {quote.shippingAddress.lineFive && (
                                                <li>{quote.shippingAddress.lineFive}</li>
                                            )}
                                            {quote.shippingAddress.postCode && (
                                                <li>{quote.shippingAddress.postCode}</li>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </div>
                            <div>
                                <ul className="customer-address">
                                    {quote.billingAddress && (
                                        <>
                                            <b>Billing Address</b>
                                            {quote.billingAddress.lineOne && (
                                                <li>{quote.billingAddress.lineOne}</li>
                                            )}
                                            {quote.billingAddress.lineTwo && (
                                                <li>{quote.billingAddress.lineTwo}</li>
                                            )}
                                            {quote.billingAddress.lineThree && (
                                                <li>{quote.billingAddress.lineThree}</li>
                                            )}
                                            {quote.billingAddress.lineFour && (
                                                <li>{quote.billingAddress.lineFour}</li>
                                            )}
                                            {quote.billingAddress.lineFive && (
                                                <li>{quote.billingAddress.lineFive}</li>
                                            )}
                                            {quote.billingAddress.postCode && (
                                                <li>{quote.billingAddress.postCode}</li>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>

                        <QuoteOrderItemsTable orderLineItems={quote.lines} />

                        <br />
                        <div className="row justify-content-end">
                            {quote && (
                                <div className="col-md-3 ml-auto">
                                    <p className="fw-bold">
                                        Total Without Tax: {quote.totalWithoutTax}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="row justify-content-end">
                            {quote && (
                                <div className="col-md-3 ml-auto">
                                    <p className="fw-bold">Tax Amount: {quote.taxAmount}</p>
                                </div>
                            )}
                        </div>

                        <hr />
                        <div className="row justify-content-end">
                            {quote && (
                                <div className="col-md-3 ml-auto">
                                    <p className="fw-bold">Total Amount: {quote.total}</p>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </>
        </AppPage>
    );
};

export default SpecificQuote;
