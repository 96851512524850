import {ApiError} from "./ApiClient";
import { Buffer } from "buffer"
import {toast} from "react-toastify";
import {Credentials} from "../credientials/CredentialsService";

export const basicAuthHeaderForCredentials: (credentials: Credentials) => string = (credentials: Credentials) => {
    const basicAuth = credentials.email + ':' + credentials.password;
    let buff = new Buffer(basicAuth);
    return 'Basic ' + buff.toString('base64');
}

export const getApiError: (err: any) => ApiError = (err: any) => {
    if (!err || !err.response) {
        return undefined;
    }
    try {
        return err.response.data;
    } catch (e) {
        return undefined;
    }
}

export const logApiError: (defaultMessage: string, err?: any) => void = (defaultMessage: string, err?: any) => {
    let apiError: ApiError = getApiError(err);
    if (apiError !== undefined) {
        toast.error(apiError.message);
    } else {
        toast.error(defaultMessage);
        console.log(err);
    }
}